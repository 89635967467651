import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { MeetingComponent } from './meeting/meeting.component';

const routes: Routes = [
  // { path: '', redirectTo: '', pathMatch: 'full' },
  // { path: '', component: MainComponent, pathMatch: 'full' },
  // { path: 'main', component: MainComponent },
  // { path: '**', component: MainComponent }  

  { path: '', component: MainComponent }, // Default route
  { path: 'join/:name', component: MeetingComponent }, // Default route
  
  { path: '**', redirectTo: '' } // Redirect any other route to the default route


];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:true})],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
