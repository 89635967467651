import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-draggable-button',
  templateUrl: './draggable-button.component.html',
  styleUrls: ['./draggable-button.component.css']
})
export class DraggableButtonComponent {
  @ViewChild('dragButton', { static: true }) dragButton!: ElementRef;

  isDragging = false;
  currentX = 0;
  currentY = 0;
  initialX = 0;
  initialY = 0;
  xOffset = 0;
  yOffset = 0;
  isMobile:boolean=false
  get x() { return this.xOffset; }
  get y() { return this.yOffset; }

  constructor(private deviceService: DeviceDetectorService)
  {
    if(this.deviceService.isMobile())
    {
      this.xOffset=100;
      this.yOffset = 30;
      this.isMobile=true

    }
    else
    {
      this.xOffset=900;
    }
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMove(e: MouseEvent | TouchEvent) {
    if (this.isDragging) {
      e.preventDefault();
      if (e instanceof MouseEvent) {
        this.currentX = e.clientX - this.initialX;
        this.currentY = e.clientY - this.initialY;
      } else {
        this.currentX = e.touches[0].clientX - this.initialX;
        this.currentY = e.touches[0].clientY - this.initialY;
      }

      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onEnd(e: MouseEvent | TouchEvent) {
    if (this.isDragging) {
      this.initialX = this.currentX;
      this.initialY = this.currentY;
      this.isDragging = false;
    }
  }

  startDrag(e: MouseEvent | TouchEvent) {
    if (e instanceof MouseEvent) {
      this.initialX = e.clientX - this.xOffset;
      this.initialY = e.clientY - this.yOffset;
    } else {
      this.initialX = e.touches[0].clientX - this.xOffset;
      this.initialY = e.touches[0].clientY - this.yOffset;
    }

    if (e.target === this.dragButton.nativeElement) {
      this.isDragging = true;
    }
  }
}
