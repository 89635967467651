<div class='main' *ngIf="!meeting">
    <div class='container'>
      <div class='row'>
        <div class='span six html5clientOnly input-center'>
          <div class='join-meeting'>
            <form [formGroup]="meetingForm" (ngSubmit)="onSubmit()">
              <input type="text" formControlName="username" placeholder="Enter Your Name" size="29"
                class="field input-default" autofocus>
              <input type="submit" value="Join" class="submit_btn button success large"><br>
              <input type="hidden" formControlName="isModerator">
              <input type="hidden" formControlName="action">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="container-fluid"*ngIf="meeting" >
    <iframe style="width:100%;height:100vh;margin:auto" [src]="authorization_url" title="Payment"></iframe>
</div>
  