import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccessService } from '../shared/services/access.service';
import { AppError } from '../shared/errors/app-error';
import { BadInput } from '../shared/errors/bad-input';
import { NotFoundError } from '../shared/errors/not-found-error';
import { NotAuthorizedError } from '../shared/errors/not-authorized-error';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css']
})
export class MeetingComponent implements OnInit {


  isPopupVisible: boolean = false;
  isPopupVisibleError: boolean = false;
  displayIframe:boolean=false
  @ViewChild('iframe') iframe: ElementRef;
  meetingId:string="";
  record_link:SafeResourceUrl | null = null; // Initializing as null
  authorization_url: SafeResourceUrl | null = null; // Initializing as null
  username:string=""
  status:boolean=true
  constructor(private el: ElementRef, private renderer: Renderer2,private sanitizer: DomSanitizer,
    private route:ActivatedRoute,private loaction:Location,
    private accesssService:AccessService,private http: HttpClient,
    private model:NgbModal) {
    this.username=this.route.snapshot.params.name
    if(this.username=="")
      this.loaction.back();
    else
    {

    }

  }
  receiveMessage(event: MessageEvent): void {

    
    if (event.data.data.data.method=='metamask_sendDomainMetadata') {
      //this.goToRecord()
      //return;
    }

    //console.log("go to record linkkkkkkkkkkkkkkkkss")
    //this.goToRecord()
    
    
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessage.bind(this), false);
  }
  ngOnInit(): void {
//    window.addEventListener('message', this.receiveMessage.bind(this), false);


    this.authorization_url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://meet2.eyuchat.com/demo/demoHTML5.jsp?username=${this.username}&isModerator=${this.status}&action=create`)
    
  }
  getSessionData(key: string): any {
    const data = sessionStorage.getItem(key);
    return data ?(data) : null;
  }
  ngAfterViewInit() {

  }
  getMeetingId()
  {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key=='BBB_meetingID') {
        this.meetingId=this.getSessionData(key)

      }
    }
  }
  getData() {

    let formData:FormData=new FormData();
    formData.append("username",this.username);
    formData.append("mobile_number",'01111111111');
    formData.append("title",'Meeting');
    this.accesssService.getPerAction(`https://meet2.eyuchat.com/playback/presentation/2.3/183f0bf3a0982a127bdb8161e0c44eb696b3e75c-1722351767387`).subscribe((data:any) => {

    },
      (error: AppError) => {


        if (error instanceof BadInput) {

          //alert('Error in saving.');
          //Swal.fire(error.originalError, "", "error")
        }
        else if (error instanceof NotFoundError) {
          //alert('Not Found Error.');
          //Swal.fire('Not Found Error.', "", "error")
        }
        else if (error instanceof NotAuthorizedError) {
          // alert('Not Authorized.');
          //Swal.fire('Not Authorized.', "", "error")
        } else if(error.originalError.error.message!=undefined) {
          //Swal.fire(error.originalError.error.message, "", "error")
        }
        else throw error;
      });
  }
  openRecordFun()
  {
   // window.location.href = `https://meet2.eyuchat.com/playback/presentation/2.3/${this.meetingId}`;
   //const videoUrl = 'https://meet2.eyuchat.com/playback/presentation/2.3/183f0bf3a0982a127bdb8161e0c44eb696b3e75c-1722351767387';
   //const filename = 'video.mp4';
   //this.downloadFile(videoUrl, filename);
   for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key=='BBB_meetingID') {
      this.meetingId=this.getSessionData(key)

    }
  }
   this.record_link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://meet2.eyuchat.com/playback/presentation/2.3/${this.meetingId}`)
  //  console.log(modelId)
  this.displayIframe=true
  //  this.model.open(modelId,{size:'lg'})


  }
  goToRecord()
  {
   
    this.isPopupVisibleError=false
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key=='BBB_meetingID') {
        this.meetingId=this.getSessionData(key)
        window.open(`https://meet2.eyuchat.com/playback/presentation/2.3/${this.meetingId}`, '_blank');
        return;
      }
    }
    this.isPopupVisibleError=true
    

  }

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (this.isPopupVisible && !target.closest('.overlay-content')) {
      this.closePopup();
    }
  }

  handleMessage = (event: MessageEvent) => {
    // Validate the origin of the message for security
    // if(event.data.event=='success')
    // {
    //   this.waitingScreen=true
    //   this.counter=5;
    //   let interval=setInterval(()=>{
    //     this.counter--;
    //     if(this.counter==0)
    //     {
    //       clearInterval(interval)
    //       this.location.back()
    //     }
    //   },1000)
    // }
    // // else if(event.data.event!='success'&&event.data.event!='loaded:checkout')
    // // {
    // //   Swal.fire("",event.data.data.message,'error')
    // //   this.location.back()
    // // }
    // if (event.origin !== this.url&&event.origin!='https://checkout.paystack.com') {
    //   this.location.back()
    //   return;
    // }

    // // Handle the message data
    // const message = event.data;

    // // Implement your logic to handle the message here
    // if (message.status === 'success') {
    //   // Process the success response
    // }
  }



}
