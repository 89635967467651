import { Component, OnInit, OnDestroy } from '@angular/core';
import { WakeLockService } from '../app/shared/services/wake-lock.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private wakeLockInterval: any;

  constructor(private wakeLockService: WakeLockService) {}

  ngOnInit() {
    this.wakeLockService.requestWakeLock();
    
    // Periodically request wake lock
    this.wakeLockInterval = setInterval(() => {
      this.wakeLockService.requestWakeLock();
    }, 60000); // Every minute

    // Handle visibility change
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  ngOnDestroy() {
    if (this.wakeLockInterval) {
      clearInterval(this.wakeLockInterval);
    }
    this.wakeLockService.releaseWakeLock();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.wakeLockService.requestWakeLock();
    } else {
      this.wakeLockService.releaseWakeLock();
    }
  }
}