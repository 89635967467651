<!-- app.component.html -->
<div class="container-fluid">
    <app-draggable-button (click)="openPopup();$event.stopPropagation();"></app-draggable-button>
  
    <iframe 
      #iframe 
      style="position:absolute;width:100%;height:100vh;margin:auto;z-index:1;" 
      [src]="authorization_url" 
      title="Payment">
    </iframe>
  
    <!-- Popup Overlay -->
    <div class="overlay" *ngIf="isPopupVisible" (click)="closePopup()">
      <div class="overlay-content" (click)="$event.stopPropagation()">
        <button class="close-button" (click)="closePopup()">×</button>
        <h2>Stream Record</h2>
        <p *ngIf="!isPopupVisibleError" >Records will be displayed after the meeting ends, and it may take a few minutes.</p>
        <button *ngIf="!isPopupVisibleError"  (click)="goToRecord()" class="button-go-to" >
          Go TO Record
        </button>
        <p class="text-danger"  *ngIf="isPopupVisibleError" >
            Pls End The Meeting First
        </p>

      </div>
    </div>
  </div>
  