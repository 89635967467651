import { AccessService } from './../shared/services/access.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppError } from '../shared/errors/app-error';
import { BadInput } from '../shared/errors/bad-input';
import { NotFoundError } from '../shared/errors/not-found-error';
import { NotAuthorizedError } from '../shared/errors/not-authorized-error';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
 meetingForm: FormGroup;
 meeting=false
 authorization_url: SafeResourceUrl | null = null; // Initializing as null

  constructor(private fb: FormBuilder,private router: Router,private sanitizer: DomSanitizer,private accesssService:AccessService) {
    this.meetingForm = this.fb.group({
      username: [''],
      isModerator: ['true'],
      action: ['create']
    });
  }

  ngOnInit(): void {
    window.addEventListener('message', this.handleMessage, false);

  }

  onSubmit(): void {
    if (this.meetingForm.valid) {
      // Perform form submission logic here
      //console.log(this.meetingForm.value);
      if (this.meetingForm.valid) {
        const formValue:any = this.meetingForm.value;
        //console.log('Form Submitted', formValue);
        // Perform your form submission logic here
        // For example, navigate to another page or call a service
        // this.router.navigate([''], { queryParams: formValue }).then(() => {
        //   // After navigation, reload the route to force refresh
        //   window.location.reload();
        // });
        // let url="https://meet2.eyuchat.com/demo/demoHTML5.jsp
        // window.open(url, '_blank');
        const url = this.router.createUrlTree([`https://meet2.eyuchat.com.eyuchat.com?username=${formValue.username}&isModerator=${formValue.isModerator}&action=${formValue.action}`]).toString();

        // Opening the URL in a new tab
        //window.location.href = `https://meet2.eyuchat.com/demo/demoHTML5.jsp?username=${formValue.username}&isModerator=${formValue.isModerator}&action=${formValue.action}`;
        //this.getData();


        // this.authorization_url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://meet2.eyuchat.com/demo/demoHTML5.jsp?username=${formValue.username}&isModerator=${formValue.isModerator}&action=${formValue.action}`);
        
        // console.log(this.authorization_url)
        // this.meeting=true
        this.router.navigate(['/join',formValue.username])
      }
    }
  }

  handleMessage = (event: MessageEvent) => {
    //console.log(event)
  }

  getData() {
  
    const formValue:any = this.meetingForm.value;
    this.accesssService.getPerAction(`https://meet2.eyuchat.com/demo/demoHTML5.jsp?username=${formValue.username}&isModerator=${formValue.isModerator}&action=${formValue.action}`).subscribe((data:any) => {
      //console.log(data)    
    },
      (error: AppError) => {
        
        
        if (error instanceof BadInput) {
          
          //alert('Error in saving.');            
          //Swal.fire(error.originalError, "", "error")
        }
        else if (error instanceof NotFoundError) {
          //alert('Not Found Error.');
          //Swal.fire('Not Found Error.', "", "error")
        }
        else if (error instanceof NotAuthorizedError) {
          // alert('Not Authorized.');
          //Swal.fire('Not Authorized.', "", "error")
        } else if(error.originalError.error.message!=undefined) {
          //Swal.fire(error.originalError.error.message, "", "error")
        }
        else throw error;
      });
  }
}

