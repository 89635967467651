import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BadInput } from '../errors/bad-input';
import { NotFoundError } from '../errors/not-found-error';
import { NotAuthorizedError } from '../errors/not-authorized-error';
import { AppError } from '../errors/app-error';
//import * as XLSX from 'xlsx';
//import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  token:any;
  headers:any;
  constructor(@Inject(String) private url: string, private http: HttpClient) { }

  head()
   {
    this.token=JSON.parse((String)(localStorage.getItem("currentJWT")))
    const header = new HttpHeaders().set('Authorization',"Bearer "+this.token); 
    this.headers = { headers: header };
   }
  getAll() {
    this.head()
    return this.http.get(this.url,this.headers)

      .pipe(catchError(this.handleError))
  }

  getPerAction(action: any) {
    this.head()
    return this.http.get(this.url + action,this.headers)
      .pipe(catchError(this.handleError))
  }
  getPerActionWithoutHeader(action: any) {
    return this.http.get(this.url + action)
      .pipe(catchError(this.handleError))
  }
  getCountries() {
    return this.http.get("https://countriesnow.space/api/v0.1/countries")
      .pipe(catchError(this.handleError))
  }

  getCities(country:any) {
    return this.http.get(`https://shivammathur.com/countrycity/cities/${country}`)
      .pipe(catchError(this.handleError))
  }
  emalyamiSignUp(data:any) {
    return this.http.post(`https://api1.emalyami.com/core/api/v1/@/user/create-account`,data)
      .pipe(catchError(this.handleError))
  }

  getPerActionResponeType(action: any, ResponeType: any) {
    this.head()
    return this.http.get(this.url + action, { responseType: ResponeType })
      .pipe(catchError(this.handleError))
  }

  create(resource: any) {
    this.head()
    return this.http.post(this.url, resource,this.headers)
      .pipe(catchError(this.handleError))
  }

  postPerAction(action: any, resource: any) {
    this.head()
    return this.http.post(this.url + action, resource,this.headers)
      .pipe(catchError(this.handleError))
  }
  login(action: any, resource: any) {
    this.head()
    return this.http.post(this.url + action, resource)
      .pipe(catchError(this.handleError))
  }
  generatePassword(action: any) {
    return this.http.get(this.url + action)
      .pipe(catchError(this.handleError))
  }

  update(id: any, resource: any) {
    this.head()
    return this.http.put(this.url + "/" + id, resource,this.headers)
      .pipe(catchError(this.handleError))
  }

  putPerAction(action: any, id: any, resource: any) {
    this.head()
    return this.http.put(this.url + action + "/" + id, resource,this.headers)
      .pipe(catchError(this.handleError))
  }
  putPerActionWithoutId(action: any, resource: any) {
    this.head()
    return this.http.put(this.url + action , resource,this.headers)
      .pipe(catchError(this.handleError))
  }

  delete(id: any) {
    this.head()
    return this.http.delete(this.url + "/" + id,this.headers)
      .pipe(catchError(this.handleError));
  }

  deletePerAction(action: any, id: any) {
    this.head()
    return this.http.delete(this.url + action + "/" + id,this.headers)
      .pipe(catchError(this.handleError));
  }

  public handleError(errorObj: HttpErrorResponse) {
    if (errorObj.status === 400) {
      return throwError(new BadInput(errorObj.error))
    }
    if (errorObj.status === 404) {
      return throwError(new NotFoundError(errorObj.error))
    }
    if (errorObj.status === 403) {
      return throwError(new NotAuthorizedError(errorObj.error))
    }
    if (errorObj.status === 401) {
      return throwError(new NotAuthorizedError(errorObj.error))
    }
    return throwError(new AppError(errorObj))
  }

  downloadObjectAsJson(exportObj: any, exportName: any) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

}
